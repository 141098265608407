<template>
    <section class="gardenManagementPage">
        <!-- <g-breadcrumb bread="视频列表" /> -->
        <el-button type="success" @click="addType" class="turnBule">添加分类</el-button>
        <TableContainer title="">
            <el-table :data="tableData" stripe style="width: 100%" border :header-cell-style="tabHeader"
                :cell-style="{ textAlign: 'center' }">
                <el-table-column prop="id" label="序号" min-width="60" />
                <el-table-column prop="catName" label="分类名称" min-width="60" />
                <el-table-column prop="organizationName" label="所属部门" min-width="60" />
                <el-table-column prop="regDate" label="缩略图" min-width="60">
                    <template slot-scope="scope">
                        <img :src="scope.row.catImg" width="40" height="40" />
                    </template>
                </el-table-column>
                <el-table-column prop="createTime" label="创建时间" min-width="60" />
                <el-table-column prop="videoNum" label="视频数量" min-width="60" />
                <el-table-column width="150" label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" class="textOrange" @click="editorFormClick(scope.row)">编辑</el-button>
                        <el-button type="text" class="textOrange" @click="delteFormClick(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <GPagination v-show="total > 0" ref="ref_GPagination" :total="total" :page.sync="searchForm.page"
                :size.sync="searchForm.size" @pagination="queryPxCatPage" />
        </TableContainer>







    </section>
</template>

<script>
import {
    queryPxCatPage,
    delPxCat

} from '@api/bx/api_contentManagement';
export default {
    data() {
        return {
            tiny: {
                value: undefined
            },
            themeDialog: {
                title: '信息框',
                visible: false
            },
            searchForm: {
                page: 1,
                size: 10
            },
            total: 0,
            tableData: [],
        }
    },
    created() {
        this.queryPxCatPage()

    },
    methods: {
        tabHeader() {
            return 'backgroundColor:rgba(88, 227, 232, 0.12);color:#000;font-weight:bold;font-size:15px;text-align: center;'
        },
        // 初始化列表
        queryPxCatPage() {
            queryPxCatPage(this.searchForm).then(res => {
                this.tableData = res.records
                this.total = res.total

            })
        },

        //添加
        addType() {
            this.$router.push(
                {
                    path: '/bxPxCatMenu/addType',
                    query: {
                        editor: '0',

                    }
                }
            )

        },
        //编辑
        editorFormClick(row) {
            this.$router.push(
                {
                    path: '/bxPxCatMenu/addType',
                    query: {
                        editor: '1',
                        // items:row.catContent,
                        items: JSON.stringify({
                            catContent: row.catContent,
                            catImg: row.catImg,
                            catInfo: row.catInfo,
                            organizationId: row.organizationId,
                            catName: row.catName,
                            id: row.id,
                            organizationName: row.organizationName
                        })
                    }
                }
            )
        },
        //删除
        delteFormClick(row) {
            if (row.videoNum > 0) {
                this.$message.warning('分类下有关联视频，禁止删除！')
                return
            }
            this.$confirm('永久删除' + '这条数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                delPxCat({ id: row.id }).then(res => {
                    this.queryPxCatPage()
                })
            })
        }
    }
}
</script>

<style scoped lang="scss">
/* .tree /deep/ .el-tree-node__content{
		padding-left: 40px
	} */
.turnBule {
    color: #fff;
    background-color: #58e3e8;
    border-color: rgba(56, 185, 190, 0.12);
    margin-left: 15px;
}

.turnBule:hover,
.turnBule:focus {
    background: rgba(56, 185, 190, 0.5);
    border-color: rgba(56, 185, 190, 0.5);
    color: #fff;
}

.el-button--text.reviewed {
    color: #38b9be;
}

.reviewed {
    color: #38b9be;
}

.el-tree-node__content {
    z-index: 999;
    padding-left: 40px;

}

.directory-in {
    width: 32%;
    line-height: 60px;
    background: #4095E5;
}
</style>

